import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div>
      <header>
        <h1>
          Vitaling Butaolin (布托林)
        </h1>
        <img src="vitaling.png" />
        <p>
          Hello everyone, my name is Vitaling Butaolin.
        </p>
        <p>I graduation from Tsinghua University and is interesting in the blockchain technology. Please contact me on my wechat if you have opportunity in web3 industry.</p>

        <p>大家好，我是维塔林·布托林。 我是清华大学的毕业生，对区块链技术很感兴趣。 如果您有web3行业的任何机会，请通过我的微信联系我。</p>
        <p>Twitter: https://twitter.com/vbutaolin</p>
      </header>
    </div>
  );
}

export default App;
